import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import BodyClassName from "react-body-classname";
import { useIntl, Link } from "gatsby-plugin-intl";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { connect } from "react-redux";
import Tools from "../classes/tools";

const NotFoundPage = ({ s }) => {
	const intl = useIntl();
	const query = useStaticQuery(
		graphql`
			{
				homeImage: file(relativePath: { eq: "home-01.jpg" }) {
					childImageSharp {
						fluid {
							...GatsbyImageSharpFluid
						}
					}
				}
				homeDesktopImage: file(relativePath: { eq: "home-desktop-01.jpg" }) {
					childImageSharp {
						fluid {
							...GatsbyImageSharpFluid
						}
					}
				}
			}
		`
	);
	return (
		<>
			<BodyClassName className="home">
				<Layout>
					<SEO title={Tools.convertString(s.cuatrocientoscuatro?.title)} />
					<main>
						<div
							style={{
								position: "fixed",
								top: 0,
								left: 0,
								right: 0,
								bottom: 0,
								zIndex: 1,
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<div
								style={{
									background: "white",
									paddingTop: 20,
									paddingBottom: 20,
									paddingLeft: 100,
									paddingRight: 100,
									textAlign: "center",
								}}
							>
								<p>{Tools.convertString(s.cuatrocientoscuatro?.title)}</p>
								<Link to="/">{Tools.convertString(s.cuatrocientoscuatro?.link)}</Link>
							</div>
						</div>
						<section>
							<article id="home-banner">
								<Img className="hide-md" fluid={query.homeImage.childImageSharp.fluid} alt={``} />
								<Img className="show-md" fluid={query.homeDesktopImage.childImageSharp.fluid} alt={``} />
							</article>
						</section>
					</main>
				</Layout>
			</BodyClassName>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		s: state.app.boot.strings,
	};
};

export default connect(mapStateToProps)(NotFoundPage);
